import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import PageHeader from "../../components/pageHeader"
import SEO from "../../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching from '../../components/images/imageCoaching.js'
import ImageDevelopement from '../../components/images/imageDevelopement.js'
import ImageClimat from '../../components/images/imageClimat.js'
import ImageChange from '../../components/images/imageChange.js'
import ImageConflit from '../../components/images/imageConflit.js'
import ImageMobi1 from '../../components/images/imageMobi1.js'

const Harmonisation = () => (
  <Layout>
    <SEO title="Consolidation et mobilisation d'équipe | Climat de travail et santé organisationnelle" />
    <PageHeader
        header="Consolidation et mobilisation d'équipe"
        text="Collaborer efficacement en équipe représente souvent tout un défi, chacun abordant le travail de façon bien différente. La compréhension et l’appréciation des différences individuelles ne sont pas chose simple. "
        quote="Les meilleures choses qui arrivent dans le monde de l’entreprise ne sont pas le travail d’un seul homme.  C’est le travail de toute une équipe<span>- Steve Jobs</span>"
        background="climat" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">

        <p class="service-presentation2">Oui, c’est vraiment possible de:</p>
        <div className="flex-container pad2">
            <div className="half"><ImageMobi1 /></div>
            <div className="half">
                <ul className="success">
                    <li>Dénouer les malaises relationnels qui contribuent à créer un climat de tension au travail</li>
                    <li>Encourager l’implication et la collaboration de tous à travailler avec une réelle synergie d’équipe</li>
                </ul>
            </div>

        </div>
        <p class="service-presentation2">L’atelier <b>« Tirer profit de nos différences »</b> vous permet de maximiser la cohésion au sein de vos équipes. Adapté à votre réalité, cet atelier de consolidation et de mobilisation d’équipe (team building) table sur la mise en commun des talents diversifiés.  </p>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        </ScrollAnimation>

    </div>
  </Layout>
)

export default Harmonisation
